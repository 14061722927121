<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="filter.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="addedToday"
            @change="listSales(addedToday)"
          />
          <label class="form-check-label"> Ajoutée aujourd'hui </label>
        </div>
      </div>
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'sales-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer une nouvelle vente
        </button>
      </div>
    </div>
    <br />
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Client</th>
          <th scope="col">Date</th>
          <th scope="col">M. Total</th>
          <th scope="col">M. Payé</th>
          <th scope="col">Créer par</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(sale, index) in sales.filter((p) => p.remark)"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>
          <td>
            {{ sale.reference }}
          </td>
          <td>
            {{ sale.customerFullName }}
          </td>
          <td>{{ sale.date }}</td>
          <td>
            {{ sale.totalPriceTTC }}

            DH
          </td>

          <td>
            <span class="text-success text-decoration-underline">
              {{ sale.amountPaid }}
            </span>
          </td>

          <td>
            <span v-if="sale.userName">
              {{ sale.userName }}
            </span>
          </td>

          <td>
            {{ sale.remark }}
          </td>

          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'sales-details',
                  params: { reference: sale.reference },
                })
              "
              class="btn p-0 m-0"
            >
              <i class="bi bi-info-square p-0 m-0"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {},
    };
  },
  computed: {
    ...mapGetters("sale", {
      sales: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("sale/getAll");
    this.$store.dispatch("customer/getAll");
    // setInterval(() => {
    //   this.$store.dispatch("sale/getAll");
    // }, 3000);
  },
  methods: {
    async search(value) {
      return await this.$store.commit("sale/search", value);
    },
    async refresh() {
      await this.$store.dispatch("sale/getAll");
      await this.$store.dispatch("customer/getAll");
    },

    async listSales(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.dispatch("sale/getTodays");
      } else {
        return await this.$store.dispatch("sale/getAll");
      }
    },
    async sort(value) {
      return await this.$store.commit("sale/sort", value);
    },
    async toFilter(data) {
      return await this.$store.dispatch("sale/filter", data);
    },
  },
};
</script>
